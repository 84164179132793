<template>
  <perfect-scrollbar
    class="py-4 px-2 ContentsBox"
    :options='scrollOption'>

    <div class="px-3">
      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">申請者氏名</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{userInfo.sei}} {{userInfo.mei}}
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">申請番号</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{base.code}}
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-2">
        <b-col sm="3" class="label">
          <label class="labelText bold">現在のステータス</label>
        </b-col>
        <b-col sm="9" class="flex flexMiddle">
          {{statusTexts[base.status]}}
        </b-col>
      </b-row>

      <hr>

      <b-row class="">
        <b-col sm="3" class="label">
          <label class="labelText bold">合否</label>
        </b-col>
        <b-col sm="4">
          <b-form-select
            :disabled="!hasAdoptionAuth || !onGoing"
            class="h-50px"
            @change="$emit('setJudgeStatus', $event)"
            :value="judgeStatus"
            :options="judgmentOption">
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <hr v-if="stageData.is_last === 1">

      <b-row v-if="stageData.is_last === 1">
        <b-col sm="3" class="label">
          <label class="labelText bold">
            <!-- 大学講座寄付 -->
            <span v-if="base.series_id === 1">寄付年額</span>
            <span v-else>助成金額</span>
          </label>
        </b-col>
        <b-col sm="4">
          <b-form-input
            :disabled="!hasAdoptionAuth || !onGoing || judgeStatus !== 1"
            @input="$emit('setGrantedMoney', $event)"
            class="h-50px"
            maxlength="11"
            :value="grantedMoney">
          </b-form-input>
        </b-col>
      </b-row>
    </div>

    <div class="eval-wrap mt-4 p-3">
      <div
        v-for="(evalData, stage) in evalData" :key="`eval_${stage}`"
        class="mt-2">
        <b-row class="">
          <b-col sm="3" class="label">
            <label class="labelText bold">{{stage}}次審査の総合得点</label>
          </b-col>
          <b-col sm="4">
            <b-form-input
              disabled
              :value="score(stage)"
              class="h-50px score-input"/>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="3" class="label">
            <label class="labelText bold">{{stage}}次審査の担当者別評価</label>
          </b-col>
          <b-col sm="9">
            <!-- 項目評価 -->
            <b-table
              show-empty
              id='evalViewTable'
              table-class="evalViewTable"
              thead-class=""
              tbody-tr-class="evalDataWrap"
              :fields="evalHeaders[stage]"
              :items="evalItems[stage]"
            >
              <template v-slot:empty="scope">
                <p class="none-data">登録されている評価がありません。</p>
              </template>
            </b-table>
            <!-- コメント -->
            <b-table
              show-empty
              id='evalCommentTable'
              table-class="evalCommentTable"
              thead-class=""
              tbody-tr-class="evalDataWrap"
              :fields="evalHeaders4comment[stage]"
              :items="evalItems[stage]"
            >
              <template v-slot:empty="scope">
                <p class="none-data">登録されているコメントがありません。</p>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </div>


  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'cmsApplicationControl',
  components: {
  },
  props: {
    base: { type: Object },
    stageData: { type: Object },
    judgeStatus: {},
    grantedMoney: {},
    evalData: { type: Object, default: () => {} },
    hasAdoptionAuth: { type: Boolean },
    onGoing: { type: Boolean },
  },
  data() {
    return {
      nowIndex: this.pageIndex,
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
      scrollOption: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    judgmentOption() {
      const text = CONST_STATUS.JUDGMENT_STATUS_OPTION;
      const option = [];
      option.push({ value: 1, text: text['1'] });
      option.push({ value: 0, text: text['0'] });
      return option;
    },
    evalHeaders() {
      const headers = {};
      Object.keys(this.evalData).forEach((stage) => {
        const stageData = this.evalData[stage];
        headers[stage] = [];
        const thName = { key: 'name', label: '', sortable: false };
        headers[stage].push(thName);
        stageData.fields['1'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
        stageData.fields['2'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
      });
      return headers;
    },
    evalHeaders4comment() {
      const headers = {};
      Object.keys(this.evalData).forEach((stage) => {
        const stageData = this.evalData[stage];
        headers[stage] = [];
        const thName = { key: 'name', label: '', sortable: false };
        headers[stage].push(thName);
        stageData.fields['3'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
      });
      return headers;
    },
    evalItems() {
      const items = {};
      Object.keys(this.evalData).forEach((stage) => {
        const stageData = this.evalData[stage];
        items[stage] = [];
        Object.keys(stageData.assign).forEach((key) => {
          const data = {};
          data.name = `${stageData.assign[key].user_info.sei} ${stageData.assign[key].user_info.mei}`;
          if (!stageData.evals[key]) {
            items[stage].push(data);
            return;
          }
          stageData.evals[key].items.forEach((item) => {
            const idKey = String(item.evaluation_item_id);
            data[idKey] = item.evaluation || item.comment;
          });
          items[stage].push(data);
        });
      });
      return items;
    },
    score() {
      return (stage) => {
        let score = '';
        let itemScore = 0;
        let allScore = 0;
        const stageData = this.evalData[stage];
        // タイプ1（各項目）のIDリストの作成
        const itemIdList = stageData.fields[1].map((field) => {
          return field.item_id;
        });
        // タイプ2（総合評価）のIDリストの作成
        const allIdList = stageData.fields[2].map((field) => {
          return field.item_id;
        });
        Object.keys(stageData.evals).forEach((key) => {
          Object.keys(stageData.evals[key].items).forEach((key2) => {
            if (itemIdList.includes(stageData.evals[key].items[key2].evaluation_item_id)) {
              itemScore += stageData.evals[key].items[key2].evaluation;
            } else if (allIdList.includes(stageData.evals[key].items[key2].evaluation_item_id)) {
              allScore += stageData.evals[key].items[key2].evaluation;
            }
          });
        });
        if (itemScore !== 0 || allScore !== 0) {
          score = String(allScore) + (`00${itemScore}`).slice(-2);
        }
        return score;
      };
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style>
  .table thead th {
    border-top-width: 0;
    min-height: 120px;
  }

  .table thead th, .table td {
    border-color: #BBB !important;
  }

  /* 前段階評価 */
  #evalViewTable thead th:nth-of-type(1), #evalCommentTable thead th:nth-of-type(1) {
    width: 140px !important;
    padding: 5px;
    text-align:center;
  }
  #evalViewTable tbody td:not(:first-of-type) {
    text-align:center;
  }
  #evalViewTable thead th:not(:first-of-type) {
    padding: 5px;
    text-align:center;
  }
  #evalViewTable thead th:last-of-type {
    width: 130px !important;
    padding: 5px;
  }
  #evalCommentTable>tbody>tr>td {
    white-space:pre-wrap;
    word-wrap:break-word;
  }

</style>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .eval-wrap {
    background-color: #E6E6E6;
  }

  .score-input:disabled {
    background-color: #CCC !important;
    width: 130px;
    border: #BBB solid 1px;
  }

  .error {
    color: #dc3545;
  }
</style>
