<template>
  <div>
    <div class="contentsWidth mx-auto mt-4 mb-3">
      <div class="">
        <p class="title mb-0">{{base.year}}年度 {{base.name}} - {{stageData.stage}}次審査</p>
        <hr class="title">
      </div>
    </div>

    <div class="contentsWidth mx-auto err-wrap">
      <p
        v-for="(msg, index) in errMessages" :key="index"
        class="mb-0">
        {{msg}}
      </p>
      <p class="mb-0"
        v-if="!onGoing">結果発表が完了しているため、変更できません。</p>
      <p class="mb-0">{{this.errMessages['item']}}</p>
    </div>

    <b-tabs
      class="gas-tab contentsWidth mx-auto "
      nav-wrapper-class="gas-tab-wrap">
      <b-tab title="合否"
        title-link-class="px-5">
        <v-app-judgment
          :base.sync="base"
          :stageData.sync="stageData"
          :judgeStatus.sync="judgeStatus"
          :grantedMoney.sync="grantedMoney"
          :evalData.sync="evalData"
          :hasAdoptionAuth.sync="hasAdoptionAuth"
          :onGoing.sync="onGoing"
          @setJudgeStatus="setJudgeStatus"
          @setGrantedMoney="setGrantedMoney"/>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span>申請者情報</span>
        </template>
        <v-app-userinfo
          :writable="false"
          :close_datetime="base.close_datetime"/>
      </b-tab>
      <b-tab
        v-for="(page, index) in pages" :key="index">
        <template v-slot:title>
          <span>{{page.name}}</span>
        </template>
        <v-app-form
          :writable="false"
          :pageIndex="index"
          :assets="assets"
          :downloadFormFile="downloadFormFile"/>
      </b-tab>
    </b-tabs>


    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">前の申請</b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/applications/judgment/list">一覧に戻る</b-button>
      <b-button
        v-if="hasAdoptionAuth"
        @click="save"
        variant="info"
        :disabled="!onGoing || !canSave"
        class="btn btn-primary btn-lg mr-4 bold">保存</b-button>
      <b-button
        @click="exportPdf(1, apptypeId, appId)"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">PDF出力</b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg bold">次の申請</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import Userinfo from '@/components/parts/application/Userinfo.vue';
import AppForm from '@/components/gas/parts/Form.vue';
import AppJudgment from '@/components/cms/applications/Judgment.vue';
import CONST_AUTH from '@/constants/auth';

export default {
  name: 'cmsApplicationsJudgmentEdit',
  components: {
    'v-app-userinfo': Userinfo,
    'v-app-form': AppForm,
    'v-app-judgment': AppJudgment,
  },
  data() {
    return {
      stageId: null,
      errMessages: {},
      stageData: {},
      base: {},
      assets: {},
      status: null,
      // 合否採択
      judgeStatus: null,
      grantedMoney: '',
      // 選考評価
      evalData: {},
    };
  },
  computed: {
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    appId() {
      return this.$store.state.gasApplicationForm.application_id;
    },
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    filterdIdList() {
      return this.$store.state.cmsJudgmentSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.findIndex((appId) => {
        return appId === Number(this.appId);
      });
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    onGoing() {
      if (this.base.result_announcement_datetime === null) {
        return true;
      }
      const now = moment();
      const end = moment(this.base.result_announcement_datetime);
      return now.isBefore(end);
    },
    hasAdoptionAuth() {
      return this.$store.state.auth.authes.includes(CONST_AUTH.AUTH.ADOPTION);
    },
    canSave() {
      return this.judgeStatus !== null;
    },
    saveMsg() {
      let msg = '';
      if (this.stageData.is_last) {
        msg = '最終選考のため、合格の場合は採択、不合格の場合は不採択になります。\n';
      }
      msg += '現在の内容で保存してもよろしいですか？';
      return msg;
    },
  },
  methods: {
    async fetchData() {
      const param = {
        appId: this.$route.params.appId,
        stageId: this.$route.params.stageId,
      };
      this.stageId = this.$route.params.stageId;
      const response = await api.send('/api/cms/judgment/edit', param)
        .catch((err) => {
          console.error(err);
        });
      if (!response) {
        await this.alert('申請のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/applications/judgment/list' });
        return;
      }
      const { base } = response.data;
      this.base = base;
      // this.base.applicationTypeId = base.application_type_id;
      this.assets = response.data.assets;
      const { pages, answers } = response.data;
      this.$store.commit('userInfo/setDbData', response.data.userInfo);
      this.$store.commit('gasApplicationForm/setUserId', base.user_id);
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setApplicationTypeId', base.application_type_id);
      this.$store.commit('gasApplicationForm/setApplicationId', Number(param.appId));
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.stageData = response.data.stage;
      // 選考評価
      this.evalData = response.data.eval;
      // 合否採択
      this.judgeStatus = response.data.judge ? response.data.judge.pass : null;
      this.grantedMoney = base.granted_money || '';
    },
    async save() {
      if (!this.canSave) {
        return;
      }
      if (!await this.confirm(this.saveMsg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        appId: this.$route.params.appId,
        stageId: this.$route.params.stageId,
        judgeStatus: this.judgeStatus,
        grantedMoney: this.grantedMoney,
      };
      const response = await api.send('/api/cms/judgment/save', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.errMessages = err.response.data.messages;
          } else {
            await this.alert('保存に失敗しました。', false);
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('保存しました。', false);
      this.fetchData();
      this.$store.dispatch('page/offWaiting');
    },
    // 実績のPDF出力にも使えるように引数で渡す
    // type 1=>申請，2=>報告
    async exportPdf(kind, typeId, appId) {
      this.$store.dispatch('page/onWaiting');
      const params = {
        kind,
        exportAppIdList: [appId],
        typeId,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/judgment/export/pdf', params, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const fileName = `${this.base.year}_${this.base.name}_${this.userInfo.sei}${this.userInfo.mei}.pdf`;
      download.blob(response.data, fileName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$route.params.appId,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
        stageId: this.$route.params.stageId,
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/applications/judgment/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      const newIndex = this.filterdIdListIndex + 1;
      const appId = this.filterdIdList[newIndex];
      this.$router.push({ path: `/cms/applications/judgment/edit/${appId}/${this.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData();
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdListIndex - 1;
      const appId = this.filterdIdList[newIndex];
      this.$router.push({ path: `/cms/applications/judgment/edit/${appId}/${this.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData();
      this.$store.dispatch('page/offLoading');
    },
    setJudgeStatus(value) {
      if (!value) {
        this.grantedMoney = '';
      }
      this.judgeStatus = value;
    },
    setGrantedMoney(value) {
      this.grantedMoney = value;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.fetchData();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  select, input {
    height: 50px;
  }
  .stage-wrap {
    width: 200px;
    display: inline-block;
    margin-right: 20px;
  }
  .grader-wrap {
    width: 400px;
    display: inline-block;
  }

  .left-box {
    width: 620px;
    height: 100%;
    border-width: 1px;
    border-color: #CCCCCC;
  }

  .middle-box {
    width: 60px;
    height: 100%;
  }
  .arrow-wrap {
    position: relative;
  }
  .arrow-left {
    position: absolute;
    top: 370px;
    left: 9px;
    cursor: pointer;
  }
  .arrow-right {
    position: absolute;
    top: 305px;
    left: 9px;
    cursor: pointer;
  }

  .arrow-left.disabled, .arrow-right.disabled {
    cursor: not-allowed;
  }


  .right-box {
    width: 400px;
    height: 100%;
  }

  .main-box {
    background-color: #fff;
    border:solid 1px #CCC;
  }

  .main-box.left {
    height: 618px;
  }

  .main-box.right {
    height: 463px;
  }

  .item {
    width: 100%;
    height: 40px;
    background-color: #E6E6E6;
    border-radius: 25px;
    padding: 9px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 7px 0;
    cursor: pointer;
  }

  .item.checked {
    background-color: rgba(18, 40, 137, 0.2);
  }

  #selected-wrap {
    min-height: 100%;
  }
  #selectable-wrap {
    min-height: 100%;
  }

  #selected-scroll-wrap.disable {
    background-color: #F3F3F3;
    cursor: not-allowed;
  }

  .assign_number-wrap>input {
    display: inline-block;
  }

  .assign_number {
    width: 188px;
  }

  .notice-wrap {
    color: #D56E6E;
  }

  .w100 {
    width: 100%;
  }

  .err-wrap>p{
    color: #dc3545;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
</style>
